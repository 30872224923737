<template>
  <section>
    <b-row class="match-height">
      <b-col md="6" class="altezza_min_card_utenti">        
        <b-card no-body header="Utenti e loro attuale disponibilità">
          <div class="mb-3 ml-1 text-center" v-if="Caricato == false">
              <b-spinner  v-if="errore_caricamento == false"
                  variant="primary"
                  style="width: 3rem; height: 3rem;"
                  label="loading"
              />
          </div>

          <div v-else>
            <div class="scrollable-container">
              <b-list-group flush>
                <b-list-group-item class="d-flex align-items-center" v-for="(user) in this.lista_utenti" :key="user.id">
                  <b-avatar
                    size="35"
                    variant="light-success"
                    badge
                    class="badge-minimal mr-1"
                    :badge-variant="user.availability_color"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                  <span>{{ user.user_name }} {{ user.user_surname }}</span>

                </b-list-group-item>

              </b-list-group>
            </div>
          </div>

        </b-card>
      </b-col>
      
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BImg, BAvatar, BListGroup, BListGroupItem, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard, 
    BCardText,
    BAvatar,
    BImg,
    BListGroup, 
    BListGroupItem,
    BSpinner,

  },
  data() {
    return {
      userData: {},
      lista_utenti: {},

      Caricato: false,
      errore_caricamento: false,

      intervalId: null, // ID per controllare l'intervallo

    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)

    const listaUtenti = this.$http.get('api/crm/user/list_availabilities')

    Promise.all([listaUtenti]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista utenti
        this.lista_utenti = response[0].data.reply.dati
        
        this.Caricato = true;
        this.errore_caricamento = false;
        
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    controlloStatoUtenti() {
      console.log("controlloStatoUtenti in Home - start")
      this.Caricato = false;
      this.errore_caricamento = false;

      this.$http.get('api/crm/user/list_availabilities').then(response => { 
        if(response.data.statusCode == 200){
          //caricamento lista utenti
          this.lista_utenti = response.data.reply.dati
          
          this.Caricato = true;
          this.errore_caricamento = false;
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      });

    },
    stopInterval() {
      clearInterval(this.intervalId); // Ferma l'intervallo
      this.intervalId = null;
      console.log("Intervallo fermato.");
    },
  },
  mounted() {
    // Avvia l'intervallo
    this.intervalId = setInterval(this.controlloStatoUtenti, 5000); // Ogni 5 secondi
    //console.log("Intervallo avviato.");
  },
  beforeRouteLeave(to, from, next) {
    console.log("Prima di lasciare la pagina:", from.fullPath, "->", to.fullPath);

    console.log("beforeRouteLeave --------------------")
    // Pulisce l'intervallo quando il componente viene distrutto
    clearInterval(this.intervalId);

    next(); //cambio pagina
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
